import * as React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"

const IndexPage = () => {

  return (
    <Layout >
      <p className="test">test</p>
      <div className="testbox">
        <Link to="lashing">ラッシングベルト</Link>
      </div>
    </Layout>
  )
}

export default IndexPage
